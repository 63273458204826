// BaseChatComponent.tsx

import styles from '../UIGeneral/styles.module.css';
import '../ModuleChat/ModuleChat.css';
import { FormattedMessage, useIntl } from 'react-intl';
import { Panel, PanelGroup } from 'react-resizable-panels';
import ResizeHandle from '../UIGeneral/ResizeHandle';
import sendIcon from '../../images/send_icon.png';
import sendIconHeb from '../../images/send_icon_heb.png';
import userIcon from '../../images/icon-user.png';
import wisannaIcon from '../../images/wisanna_icon.png';
import waitIcon from '../../images/loading_spinner.gif';
import waitIconTransparent from '../../images/loading_spinner_transparent.gif';
import wordIcon from '../../images/word_icon.png';
import likeIcon from '../../images/like_icon.png';
import dislikeIcon from '../../images/dislike_icon.png';
import likeIconClicked from '../../images/like_icon_clicked.png';
import dislikeIconClicked from '../../images/dislike_icon_clicked.png';
import { useEffect, useRef, useState } from 'react';
import { useSafeAuth } from '../../firebase/AuthProvider';
import { svcChat, downloadAsWord, svcFeedbackAIResponse } from '../../Services/servicesWrapper';
import { firestoreSaveChatMessage, firestoreLoadChatMessages } from '../../firebase/firebaseCaching';
import { Chat, ChatMessage, textToHTML } from './chatUtils';
import { v4 as uuidv4 } from 'uuid';
import ReactDOM from 'react-dom';
import ChartRenderer, { ChartConfig } from '../ChartRenderer/ChartRenderer';
import { set } from 'lodash';


interface BaseChatComponentProps {
    currentChat: Chat;
    setCurrentChat: (chat: Chat) => void;
    chatMessages: ChatMessage[];
    setChatMessages: React.Dispatch<React.SetStateAction<ChatMessage[]>>;
    handleNewChat?: () => void;
    considerAddChatTitle: (chatMessages: ChatMessage[], uPrompt: string, aResponse: string) => void;
    saveChatMessage_UpdateChats?: (msg: ChatMessage) => void;
    instanceType: string
}


function BaseChatComponent({ currentChat, setCurrentChat, chatMessages, setChatMessages,
    handleNewChat, considerAddChatTitle, saveChatMessage_UpdateChats, instanceType }: BaseChatComponentProps) {
    const intl = useIntl();
    const { authState } = useSafeAuth();
    const [isWaitingForResponse, setIsWaitingForResponse] = useState(false);
    const [shouldScrollToBottom, SetShouldScrollToBottom] = useState(false);
    const [isDownloading, setIsDownloading] = useState<{ [key: string]: boolean }>({});
    const textAreaRef = useRef<HTMLTextAreaElement | null>(null);
    const panelDivMessagesRef = useRef(null); // Reference to chat messages container 
    const [feedback, setFeedback] = useState<{ [key: string]: 'like' | 'dislike' | null }>({});
    const [lastSelectedChatID, setLastSelectedChatID] = useState('');
    const [selectedAIModel, setSelectedAIModel] = useState('model1'); // Initialize with a default model
    const [isModelSelectionAllowed, SetIsModelSelectionAllowed] = useState(true); // Initialize with a default model

    //const [chartsRenderedAfterRedraw, setChartsRenderedAfterRedraw] = useState(false);
    //const [lastLatestTime, setLastLatestTime] = useState(0);

    useEffect(() => {
        const div: any = panelDivMessagesRef.current;
        if (!div || !chatMessages.length) return;
        //if (lastSelectedChatID == currentChat.id) return;

        div.querySelectorAll('.chart-container').forEach((chartDiv: any) => {
            const chartConfig: ChartConfig = JSON.parse(chartDiv.getAttribute('data-chart'));
            ReactDOM.render(<ChartRenderer config={chartConfig} />, chartDiv);
        });

        const justOpeningChat = lastSelectedChatID != currentChat.id; // && !isWaitingForResponse
        const lastMessage = chatMessages[chatMessages.length - 1];

        // Delay the scroll adjustment to ensure charts are rendered first
        const adjustScrollPosition = () => {
            if ((lastMessage && lastMessage.type === 'u') || justOpeningChat) {
                div.scrollTop = div.scrollHeight;
            } else if (lastMessage && lastMessage.type === 'a') {
                if (shouldScrollToBottom && div.scrollHeight > 0) {
                    try {
                        div.scrollTop = div.scrollHeight;
                    } catch (e) { }
                }
            }
        };

        setTimeout(adjustScrollPosition, 0); // Adjust scroll position after charts are rendered

        if (lastSelectedChatID != currentChat.id) {
            setLastSelectedChatID(currentChat.id);
        }

    }, [chatMessages, lastSelectedChatID]);

    useEffect(() => {
        const loadMessages = async () => {
            const messages = await firestoreLoadChatMessages(authState.uid || '', authState.project || '', currentChat.id);
            messages.sort((a, b) => a.date - b.date); // Sort by date
            setChatMessages(messages);
        }
        loadMessages();
    }, [currentChat.id]);

    const saveChatMessage = (msg: ChatMessage) => {
        setCurrentChat({ ...currentChat, last_updated: Date.now() });
        firestoreSaveChatMessage(authState.uid || '', authState.project || '', currentChat.id, msg.id, msg);

        if (saveChatMessage_UpdateChats) {
            saveChatMessage_UpdateChats(msg);
        }
    }

    const handleServerWordResponse = (word: string) => {
        const div: any = panelDivMessagesRef.current;
        if (div) {
            const isNearBottom = div.scrollHeight - div.scrollTop - div.clientHeight <= 10;
            SetShouldScrollToBottom(isNearBottom);
        }


        setChatMessages(prevMessages => {
            const lastMessage = prevMessages[prevMessages.length - 1];
            if (lastMessage && lastMessage.type === 'a') {
                return [...prevMessages.slice(0, -1), { ...lastMessage, text: `${lastMessage.text}${word}` }];
            } else {
                const newAssistantMessage: ChatMessage = { id: uuidv4(), date: Date.now(), type: 'a', text: word, response_completed: false };
                return [...prevMessages, newAssistantMessage];
            }
        });
    }

    const handleServerEndResponse = (uPrompt: string, aResponse: string) => {
        setChatMessages(prevMessages => {
            const lastMessage = prevMessages[prevMessages.length - 1];
            let txt = `${lastMessage.text}`.replace(/\n+$/g, '');
            saveChatMessage({ ...lastMessage, text: txt, response_completed: true });
            if (lastMessage && lastMessage.type === 'a') {
                return [...prevMessages.slice(0, -1), { ...lastMessage, text: txt, response_completed: true }];
            } else {
                return [...prevMessages];
            }
        });
        setIsWaitingForResponse(false);

        considerAddChatTitle(chatMessages, uPrompt, aResponse);
    }

    const sendMessage = async (txtMessage: string) => {
        if (txtMessage.trim()) {
            setIsWaitingForResponse(true);

            // if (selectedAIModel == 'model1') {
            //     txtMessage = `|m1:\n${txtMessage}`;
            // } else 
            if (selectedAIModel == 'model1') {
                txtMessage = txtMessage.replace(/^[a-z0-9A-Z\|]{1,5}:\s*/, '');
                txtMessage = `${txtMessage}`;
            }
            else if (selectedAIModel == 'model2') {
                txtMessage = txtMessage.replace(/^[a-z0-9A-Z\|]{1,5}:\s*/, '');
                txtMessage = `|m2:\n${txtMessage}`;
            } else if (selectedAIModel == 'model3') {
                txtMessage = txtMessage.replace(/^[a-z0-9A-Z\|]{1,5}:\s*/, '');
                txtMessage = `|m3:\n${txtMessage}`;
            }

            const newUserMessage: ChatMessage = { id: uuidv4(), date: Date.now(), type: 'u', text: txtMessage, response_completed: false };
            saveChatMessage(newUserMessage);

            setChatMessages((prevMessages: ChatMessage[]) => [...prevMessages, newUserMessage]);

            if (instanceType == "BusAssistant") {
                txtMessage = `buses:\nUser location: 53.339831974 -6.258832298\n\n${txtMessage}`;
            } else if (instanceType == "XpedITeAIAssistant") {
                txtMessage = `xpedite_analysis:\n${txtMessage}`;
            }


            let res = await svcChat(authState, currentChat.id, newUserMessage.id, txtMessage, handleServerWordResponse, handleServerEndResponse);
            if (res == 'error') {
                setIsWaitingForResponse(false);
            }
        }
    }

    const updateCorrId = (message: ChatMessage, corr_id: string) => {
        if (message?.corr_id) { return; }
        setChatMessages(prevMessages => {
            const lastMessage = prevMessages[prevMessages.length - 1];
            if (lastMessage && lastMessage.type === 'a') {
                return [...prevMessages.slice(0, -1), { ...lastMessage, corr_id }];
            } else {
                const newAssistantMessage: ChatMessage = { id: uuidv4(), date: Date.now(), type: 'a', corr_id, text: '', response_completed: false };
                return [...prevMessages, newAssistantMessage];
            }
        });
    }

    function findLastUserMessageBefore(m: ChatMessage): ChatMessage | undefined {
        let lastUserMessage: ChatMessage | undefined;

        for (const message of chatMessages) {
            if (message.type === 'u' && message.date < m.date) {
                lastUserMessage = message;
            }
            if (message.id === m.id) {
                break;
            }
        }

        return lastUserMessage;
    }

    const handleDownloadClick = async (message: ChatMessage) => {
        setIsDownloading(prevState => ({ ...prevState, [message.id]: true }));
        try {
            const lang = intl.formatMessage({ id: 'global.language' });
            const lastUserMessage = findLastUserMessageBefore(message);
            const downloadUrl = await downloadAsWord(authState, message.id, lastUserMessage?.text || '', textToHTML(message.text, lang, null), lang);
            if (downloadUrl) {
                const a = document.createElement('a');
                a.href = downloadUrl;
                a.download = `response_${message.id}.docx`;
                a.click();
            }
        } catch (error) {
            console.error('Download failed:', error);
        } finally {
            setIsDownloading(prevState => ({ ...prevState, [message.id]: false }));
        }
    }

    const handleFeedback = (corrId: string, sentiment: 'like' | 'dislike') => {
        let feedbackSentToBackend = false; // React executed the code inside setFeedback twice (especially in debug), so track and avoid that
        setFeedback(prevFeedback => {

            const currentFeedback = prevFeedback[corrId];
            let newFeedback;
            let feedbackToSend: 'like' | 'dislike' | 'like-remove' | 'dislike-remove';

            if (currentFeedback === sentiment) {
                // If clicking the same button again, remove the feedback
                const { [corrId]: _, ...rest } = prevFeedback;
                newFeedback = rest;
                feedbackToSend = `${sentiment}-remove` as 'like-remove' | 'dislike-remove';
            } else {
                // Otherwise, set or update the feedback
                newFeedback = { ...prevFeedback, [corrId]: sentiment };
                feedbackToSend = sentiment;
            }

            // Send feedback to the backend
            if (!feedbackSentToBackend) {
                feedbackSentToBackend = true;
                svcFeedbackAIResponse(authState, currentChat.id, corrId, feedbackToSend);
            }

            return newFeedback;
        });
    };

    function considerAllowModelSelection() {
        if (authState?.allowedFeatures?.length && authState?.allowedFeatures.includes('models_selection')) {
            SetIsModelSelectionAllowed(true);
        }
    }
    useEffect(() => {
        considerAllowModelSelection();
    }, [authState.allowedFeatures]);

    let is_xpedite_analysis = window.location.href.toLowerCase().includes('/xpedite-ai-analysis');
    // ------ JSX Structure --------
    return (
        <>
            {(instanceType === "XpedITeAIAssistant") ? (<style>{`footer, .message-download-icon { display: none !important; } `}</style>) : (<></>)}

            <PanelGroup className="chat-engagement-panes" autoSaveId="moduleChatPaneEngagementResizer" direction="vertical">
                <Panel className={styles.Panel} collapsible={false} defaultSize={80} minSize={10} order={1}>
                    <div className='panelDiv' ref={panelDivMessagesRef}>

                        {chatMessages.map((message, index) => (
                            <div key={index} className={`d-flex mb-2 ${message.type === "u" ? "message-user" : "message-assistant"}`} >
                                <div className='message-icon-container'>
                                    <img src={message.type === "u" ? userIcon : wisannaIcon} alt="Icon" width="32" />
                                </div>
                                <div className="flex-grow-1">
                                    <div className="flex-grow-1 message-content" dangerouslySetInnerHTML={{
                                        __html: textToHTML(message.text, intl.formatMessage({ id: 'global.language' }),
                                            (corr_id: string) => { updateCorrId(message, corr_id); })
                                    }}>
                                        {/* message text will be inserted here */}
                                    </div>
                                    {message.type === 'a' && message.corr_id && (message.response_completed || !isWaitingForResponse) && (
                                        <div className='message-feedback-icons'>
                                            <img
                                                src={feedback[message.corr_id || ''] === 'like' ? likeIconClicked : likeIcon}
                                                alt="Like"
                                                onClick={() => handleFeedback(message.corr_id || '', 'like')}
                                                className={feedback[message.corr_id || ''] === 'like' ? 'clicked' : ''}
                                            />
                                            <img
                                                src={feedback[message.corr_id || ''] === 'dislike' ? dislikeIconClicked : dislikeIcon}
                                                alt="Dislike"
                                                onClick={() => handleFeedback(message.corr_id || '', 'dislike')}
                                                className={feedback[message.corr_id || ''] === 'dislike' ? 'clicked' : ''}
                                            />
                                        </div>
                                    )}
                                </div>
                                {message.type === 'a' && (message.response_completed || !isWaitingForResponse) && (
                                    <div className='message-download-icon'>
                                        <div className='icon-wrapper'>
                                            <img
                                                src={isDownloading[message.id] ? waitIconTransparent : wordIcon}
                                                alt="Download"
                                                className={isDownloading[message.id] ? 'wait-icon' : 'word-icon'}
                                                onClick={() => handleDownloadClick(message)}
                                                style={{ cursor: isDownloading[message.id] ? 'default' : 'pointer' }}
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                </Panel>
                <ResizeHandle className='splitter-horizontal' />
                <Panel className={styles.Panel} collapsible={false} defaultSize={20} minSize={10} order={2}>
                    <div className="bottomContainer">
                        <div className="inputContainer">
                            <textarea
                                ref={textAreaRef}
                                placeholder={intl.formatMessage({ id: 'chat.typeAMessage' })}
                                className="mr-2"
                                disabled={isWaitingForResponse}
                                onKeyPress={(e) => {
                                    if (e.shiftKey && e.charCode === 13) {
                                        return true;
                                    }
                                    if (e.charCode === 13) {
                                        e.preventDefault();
                                        if (textAreaRef.current) {
                                            const messageText = textAreaRef.current.value;
                                            sendMessage(messageText);
                                            textAreaRef.current.value = ""; // Clear the textarea after sending
                                        }
                                    }
                                }}
                            />
                            {isModelSelectionAllowed && !is_xpedite_analysis && (
                                <select className="modelSelect"
                                    aria-label="Select AI model"
                                    disabled={isWaitingForResponse}
                                    value={selectedAIModel}
                                    onChange={(e) => setSelectedAIModel(e.target.value)} // Set the selected model state
                                >
                                    {/* <option value="model0"><FormattedMessage id="chat.model0" /></option> */}
                                    <option value="model1"><FormattedMessage id="chat.model1" /></option>
                                    <option value="model2"><FormattedMessage id="chat.model2" /></option>
                                    <option value="model3"><FormattedMessage id="chat.model3" /></option>
                                </select>
                            )}

                            {isWaitingForResponse ? (
                                <img className='icon-response-loading' src={waitIcon} alt="Loading" />  // Replace with your loading icon path
                            ) : (
                                <button
                                    className="sendButton"
                                    disabled={isWaitingForResponse}
                                    style={intl.formatMessage({ id: 'global.direction' }) == 'rtl' ? { left: 15 } : { right: 15 }}
                                    onClick={() => {
                                        if (textAreaRef.current) {
                                            const messageText = textAreaRef.current.value;
                                            sendMessage(messageText);
                                            textAreaRef.current.value = ""; // Clear the textarea after sending
                                        }
                                    }}
                                >
                                    <img src={intl.formatMessage({ id: 'global.direction' }) == 'rtl' ? sendIconHeb : sendIcon} alt="Send" />
                                </button>
                            )}
                        </div>
                    </div>
                </Panel>
            </PanelGroup>
        </>
    );
}

export default BaseChatComponent;